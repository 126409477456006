import Vue from 'vue'
import LoadScript from 'vue-plugin-load-script'
import { firestorePlugin } from 'vuefire'
import vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'

import App from './App.vue'
import { store } from './store'
import './registerServiceWorker'
import config from './plugins/config'
import logger from './plugins/logger'
import user from './plugins/user'
import vuetify from './plugins/vuetify'
import router from './router'
import fb from './firebase'
import linkify from 'vue-linkify'
import VueNativeNotification from 'vue-native-notification'
import VueInstaller from "vue-pwa-installer"
import * as Sentry from '@sentry/browser'
import { Integrations } from "@sentry/tracing"
import { Vue as VueIntegration } from '@sentry/integrations'

import 'video.js/dist/video-js.css'
import '@/styles/videojs/index.css'
import '@/styles/global.scss'

if (process.env.VUE_APP_ENV !== 'local') {
  Sentry.init({
    environment: process.env.VUE_APP_ENV,
    dsn: 'https://cd4fdfd6668c4bfbaca9928cae0c2811@o429070.ingest.sentry.io/5375068',
    integrations: [
      new Integrations.BrowserTracing(),
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true,
        tracing: true,
        tracingOptions: {
          trackComponents: true,
        }
      })
    ],
    tracesSampleRate: 1.0 // TODO: Lower as traffic rises
  });
}

Vue.config.productionTip = false

Vue.use(config)
Vue.use(logger)
Vue.use(firestorePlugin)
Vue.use(LoadScript)
Vue.use(user)
Vue.use(vuelidate)
Vue.use(VueTheMask)
Vue.use(VueNativeNotification, {
  requestOnNotify: true
})
Vue.use(VueInstaller)

Vue.directive('linkified', linkify)
Vue.config.performance = process.env.NODE_ENV !== "production"

/* eslint-disable no-new */
const app = new Vue({
  el: '#app',
  store,
  vuetify,
  router,
  mixins: [user],
  mounted() {
    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db.collection('users').doc(user.uid).get()
          .then(userSnapshot =>
            this.$store.dispatch('autoSignIn', {
              ...userSnapshot.data(),
              ...user
            })
          )
      }
    })
  },
  render: h => h(App)
})

// TODO: Remove assignment to window.
window.$app = store.$app = app
