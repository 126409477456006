import firebase from "firebase/app"
import "firebase/analytics"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"
// import "firebase/remote-config"

const firebaseConfig = (typeof process.env.FIREBASE_CONFIG === "string") ?
  JSON.parse(process.env.FIREBASE_CONFIG) :
  process.env.FIREBASE_CONFIG

/* eslint-disable no-console */
if (firebaseConfig.projectId !== 'nuokka-prod') {
  console.log(firebaseConfig.projectId)
}
/* eslint-enable no-console */

firebase.initializeApp(firebaseConfig)

let analytics = false
const hasIndexedDB = (
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB
)
if (hasIndexedDB) {
  analytics = firebase.analytics()
}

const db = firebase.firestore()
const FieldPath = firebase.firestore.FieldPath
const storage = firebase.storage()
const auth = firebase.auth()
const func = firebase.functions()
// const remoteConfig = firebase.remoteConfig()

// remoteConfig.settings = {
//   minimumFetchIntervalMillis: 3600000  // 1 hour
// }

// remoteConfig.defaultConfig = ({})

const getCurrentUser = () => new Promise((resolve, reject) => {
  const unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe()
    if (user) {
      db.collection('users').doc(user.uid).get()
        .then(userSnapshot => resolve({
          ...userSnapshot.data(),
          ...user
        }))
    } else {
      resolve(user)
    }
  }, reject)
})

export default {
  firebaseConfig,
  analytics,
  db,
  FieldPath,
  storage,
  auth,
  func,
  getCurrentUser,
  // remoteConfig
}
