export const preloadImage = (url) => {
  new Image().src = url
  return url
}

export const getImageURL = (original, width, height, cacheBust) => {
  const host = process.env.VUE_APP_ENV === 'local' ?
    'https://baal.nuokka.com' :
    `https://${window.location.host}`
  const prefix = '/cdn/img/'

  original = decodeURIComponent(original)
  width = width ? 'width=' + width : undefined
  height = height ? 'height=' + height : undefined

  const options = [width, height].join(',')
  const pathDivider = original[0] === '/' ? '' : '/'

  const url = [
    host,
    prefix,
    options,
    pathDivider,
    original,
    cacheBust ? '?' + cacheBust : ''
  ].join('')

  return preloadImage(url)
}
