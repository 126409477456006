let platform = { isWin: false, isOsX:false, isNix:false };

var appVer = navigator.appVersion;

if      (appVer.indexOf("Win")!=-1)   platform.isWin = true;
else if (appVer.indexOf("Mac")!=-1)   platform.isOsX = true;
else if (appVer.indexOf("X11")!=-1)   platform.isNix = true;
else if (appVer.indexOf("Linux")!=-1) platform.isNix = true;

export const getPathSep = () => platform.isWin ? '\\' : '/';
