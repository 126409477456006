export const accountStatus = {
  DISABLED: "disabled",
  ACTIVE: "active",
  DEMO: "demo"
}

export const postStatus = {
  PROCESSING: 'processing',
  ACTIVE: 'active',
  DISABLED: 'disabled',
  DELETED: 'deleted'
}

export const userStatus = {
  DISABLED: "disabled",
  ACTIVE: "active"
}
