import stringify from 'json-stable-stringify'

export default {
  compareItemsWithoutQuantity: (itemA, itemB) => {
    const itemX = { ...itemA } // Shallow copy
    const itemY = { ...itemB }
    const optionsX = { ...itemX.options } // Overcome shallow for options
    const optionsY = { ...itemY.options }

    delete optionsX.quantity
    delete optionsY.quantity

    itemX.options = optionsX
    itemY.options = optionsY

    const comparatorX = stringify(itemX)
    const comparatorY = stringify(itemY)

    return comparatorX === comparatorY
  },

  initList: function(list) {
    if (!list) {
      list = {
        account: null,
        modified: new Date().getTime(),
        items: []
      }
    }

    return list
  },

  addItem: function(list, item) {
    list = this.initList(list)

    let added = false

    for (let i = 0, j = list.items.length; i < j; i++) {
      if (this.compareItemsWithoutQuantity(list.items[i], item)) {
        list.items[i].options.quantity += item.options.quantity
        added = true
        break
      }
    }

    if (!added) {
      list.items.push(item)
    }

    list.modified = new Date().getTime()

    return list
  }
}
